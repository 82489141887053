<script>
import AuthenticationCode from "@/components/authentication-code";
import {userService} from "@/helpers/fakebackend/user.service";
import {requestMessage} from "@/helpers/request-message";
import Swal from "sweetalert2";
export default {
  name: "account-verify",
  components: {AuthenticationCode},
  data() {
    return {
      email: "",
      submitted: false,
      regError: "",
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      step: 1,
      password: "",
      confirmPassword: "",
      code: "",
      token: "",
    };
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.autocompleteCode();
  },
  methods: {
    autocompleteCode() {
      const params = this.paramsToJson(window.location.href);
      if (Object.keys(params).length >= 1) {
        if (params.email)
          this.email = params.email;
        if (params.otccode && params.email) {
          const paramCode = `${parseInt(params.otccode)}`;
          if (!isNaN(parseInt(paramCode)) && paramCode.length === 6) {
            this.code = paramCode;
            this.verifyAuthenticationcode();
          }
        }
      }
    },
    paramsToJson(url) {
      const params = unescape(url).split("?")[1]?.split("&");
      let objectParams = {};
      params?.forEach((param) => {
        const _param = param.split("=");
        objectParams[_param[0]] = _param[1];
      });
      return objectParams;
    },
    runStepAction(){
      switch (this.step){
        case 1:
          this.verifyAuthenticationcode();
          break;
        case 2:
          this.setPassword();
          break;
      }
    },
    updateCode(code){
      this.code = code;
      if (code.length === 6 && this.email)
        this.verifyAuthenticationcode();
    },
    verifyAuthenticationcode(){
      this.submitted = true;
      if (this.code.length !== 6){
        this.isRegisterError = true;
        this.regError = "Geben Sie einen gültigen Code ein";
        this.submitted = false;
        return;
      }
      const self = this;
      userService.userVerifyAccount(this.code, this.email).then((data)=>{
        this.submitted = false;
        this.clearError();
        self.step ++;
        this.token = data.content.auth_token;
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    setPassword(){
      this.submitted = true;
      if (this.password === "" && this.password === this.confirmPassword){
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = "Geben Sie bitte ein Password ein";
        return;
      }
      if (this.password !== this.confirmPassword){
        this.isRegisterError = true;
        this.submitted = false;
        this.regError = "Die beiden Passworte müssen gleich sein";
        return;
      }
      userService.addPasswordUserAccount(this.password, this.confirmPassword, this.token).then((data)=>{
        this.submitted = false;
        this.step++;
        this.registerSuccess = true;
        Swal.fire("Erfolgreiche Validierung", "", "success");
        this.clearError();
        this.authentication(data.content);
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de ?? "Ein Fehler ist aufgetreten";
      });
    },
    authentication(user){
      userService.login(user).then(data=>{
        if(data) {
          this.$router.push({name: "home"});
        }else{
          this.isRegisterError = true;
          this.regError = "Ein Fehler ist während der Authentifizierung aufgetreten. Gehen Sie auf die Login-Seite, um sich einzuloggen";
        }
      });
    },
    clearError(){
      this.isRegisterError = false;
      this.regError = "";
    },
    resendAuthenticationcode() {
      if (this.email){
        this.submitted = true;
        this.isRegisterError = false;
        userService.verifyUserResendCode(this.email).then((data)=>{
          this.submitted = false;
          this.clearError();
          Swal.fire(requestMessage[data.code].de, "", "success");
        }).catch((data)=>{
          this.submitted = false;
          this.isRegisterError = true;
          this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
        });
      }else{
        this.isRegisterError = true;
        this.regError = "Geben Ihre E-Mail-Adresse ein";
      }
    },
  }
}
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content d-flex min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">

                  <div v-if="!registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>

                      <div class="mt-5">
                        <h3 class="font-size-20 mb-4 font-weight-bold">Validierung der E-Mail-Adresse</h3>

                        <b-alert
                            v-model="isRegisterError"
                            class="mt-3"
                            variant="danger"
                            dismissible
                        >{{regError}}</b-alert>

                        <b-overlay :show="submitted">
                          <form class="form-horizontal" v-on:submit="(e)=>e.preventDefault()">
                            <div class="form-group auth-form-group-custom mb-4" v-show="step===1">
                              <input
                                  v-model="email"
                                  type="email"
                                  required
                                  class="form-control"
                                  id="email"
                                  placeholder="E-Mail-Adresse"
                              />
                            </div>
                            <div class="form-group auth-form-group-custom mb-4" v-show="step===1">
                              <h4 class="font-size-18 mt-4 font-weight-bold">Code einfügen</h4>
                              <authentication-code :digit="6" :value="code" v-on:code-change="updateCode"></authentication-code>
                              <div class="mt-2">Haben den Code nicht bekommen ? <a href="" class="underline-primary" @click="(e) => { e.preventDefault(); resendAuthenticationcode()}">Code erneut senden</a></div>
                            </div>

                            <div  v-show="step===2">
                              <div class="mb-4">
                                <div class="password-container">
                                  <input
                                      v-model="password"
                                      type="password"
                                      class="form-control"
                                      id="password"
                                      placeholder="Passwort"
                                  />
                                  <span class="password-btn"><i class="fa fa-eye"></i></span>
                                </div>
                              </div>
                              <div class="mb-4">
                                <div class="password-container">
                                  <input
                                      v-model="confirmPassword"
                                      type="password"
                                      class="form-control"
                                      id="confirmPassword"
                                      placeholder="Bestätigung des Passwortes"
                                  />
                                  <span class="password-btn"><i class="fa fa-eye"></i></span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <button
                                  class="btn btn-primary w-md waves-effect waves-light"
                                  type="button" @click="runStepAction"
                              >
                                <span v-if="step === 1">Los geht's</span>
                                <span v-if="step === 2">Weiter</span>
                              </button>
                            </div>
                          </form>
                        </b-overlay>
                      </div>
                    </div>
                  </div>

                  <div v-if="registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>
                    </div>
                    <b-alert
                        v-model="registerSuccess"
                        class="mt-3"
                        variant="success">
                      Die Registrierung wurde erfolgreich abgeschloßen. Sie werden in Kürze auf die Loginseite weitergeleitet.
                    </b-alert>
                    <div>
                      <router-link
                          tag="a"
                          to="/login"
                          class="font-weight-medium text-primary"
                      >Zurück zum Einloggen</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg ml-auto">
              <div class="p-4 text-white">
                <h1 class="text-primary ml-4">E-Mail-Adresse <span class="text-white">Validieren</span></h1>
                <div class="underline-primary  ml-4 mt-2 mb-4" style="width: 200px"></div>
                <div class="content">
                  <div class="row">
                    <div class="col-8">
                      <img src="@/assets/images/icons/team.png" alt="Team">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

</style>